@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.activew {
  color: var(--white);
  font-weight: 500;
  border-radius: 13.5px;
  background: rgb(239, 108, 37);
  background: -moz-linear-gradient(
    61deg,
    var(--primary) 31%,
    var(--primary2) 86%
  );
  background: -webkit-linear-gradient(
    61deg,
    var(--primary) 31%,
    var(--primary2) 86%
  );
  background: linear-gradient(61deg, var(--primary) 31%, var(--primary2) 86%);
}
